<template>
  <div class="forgotPassword">
    <main>
      <router-link to="/">
        <img class="logo__large" src="@/assets/icons/logo_white.svg" alt="teamee Logo" title="teamee - Home" />
      </router-link>
      <div class="content">
        <h2>Passwort vergessen?</h2>
        <p>Um dein Passwort zurückzusetzen, gib deine E-Mail Adresse an, welche mit deinem Konto verknüpft ist.</p>
        <form v-on:submit.prevent="validateForm">
          <input
            class="input__text__rounded"
            :class="{ input__text__rounded__danger: $v.email.$error }"
            type="mail"
            placeholder="E-Mail"
            title="E-Mail"
            v-model.trim="$v.email.$model"
          />
          <button class="button__cta" :disabled="isLoading">
            <span class="loader" v-if="isLoading"></span>
            <span class="txt" v-else>Passwort zurücksetzen</span>
          </button>
        </form>
        <ul v-if="errors">
          <li v-for="(item, index) in errors" :key="index">
            <p>{{ item[0] }}</p>
          </li>
        </ul>
        <router-link to="/"><button class="button__transparent">Zurück</button></router-link>
      </div>
    </main>
    <AppFooterRegistration />
  </div>
</template>

<script>
import AppFooterRegistration from "@/components/AppFooterRegistration.vue";
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "ForgotPassword-1",
  components: {
    AppFooterRegistration,
  },
  data() {
    return {
      email: "",
      isLoading: false,
      errors: [],
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        // if Form is valid -> store Information and submit Form
        this.$store
          .dispatch("sendResetPasswordRequest", { email: this.email })
          .then(() => {
            this.isLoading = false;
            this.$router.push("ForgotPassword-2");
          })
          .catch((error) => {
            this.isLoading = false;
            this.errors = error.response.data.errors;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

main {
  height: calc(100vh - 83px);
  display: flex;
  justify-content: center;
  align-items: center;
  .logo__large {
    position: fixed;
    top: 40px;
    left: 48px;
    @media screen and (max-width: 960px) {
      top: 20px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .content {
    max-width: 648px;
    @media screen and (max-width: 960px) {
      width: 100%;
      padding: 24px;
      align-self: flex-end;
    }
    @media screen and (max-height: 570px) {
      padding-bottom: 0;
    }
    h2 {
      @media screen and (max-width: 960px) {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 12px;
      }
      @media screen and (max-height: 570px) {
        font-size: 20px;
      }
    }
    p {
      margin-bottom: 40px;
      @media screen and (max-height: 570px) {
        line-height: 1.5;
        margin-bottom: 20px;
      }
    }
    form {
      margin-bottom: 64px;
      display: flex;
      @media screen and (max-width: 960px) {
        flex-direction: column;
      }
      @media screen and (max-height: 650px) {
        margin-bottom: 20px;
      }
      .input__text__rounded {
        width: 400px;
        margin-right: 24px;
        @media screen and (max-width: 960px) {
          width: 100%;
          margin-bottom: 24px;
        }
        @media screen and (max-height: 570px) {
          margin-bottom: 8px;
        }
      }
      .button__cta {
        width: 224px;
        @media screen and (max-width: 960px) {
          width: 100%;
        }
      }
    }
  }
}
</style>
